import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import axios from 'axios';
// Global Components
import hljs from 'highlight.js';
import Multiselect from 'vue-multiselect';
import VueFormulate from '@braid/vue-formulate';
import WootSwitch from 'components/ui/Switch';
import WootWizard from 'components/ui/Wizard';
import { sync } from 'vuex-router-sync';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import WootUiKit from '../dashboard/components';
import App from '../dashboard/App';
import i18n from '../dashboard/i18n';
import createAxios from '../dashboard/helper/APIHelper';
import commonHelpers, { isJSONValid } from '../dashboard/helper/commons';
import router, { initalizeRouter } from '../dashboard/routes';
import store from '../dashboard/store';
import constants from 'dashboard/constants/globals';
import * as Sentry from '@sentry/vue';
import 'vue-easytable/libs/theme-default/index.css';
import { Integrations } from '@sentry/tracing';
import {
  initializeAnalyticsEvents,
  initializeChatwootEvents,
} from '../dashboard/helper/scriptHelpers';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { domPurifyConfig } from '../shared/helpers/HTMLSanitizer';
import AnalyticsPlugin from '../dashboard/helper/AnalyticsHelper/plugin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import SupaLink from '../dashboard/components/ui/SupaLink';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';
import posthog from '../shared/plugins/posthog';

import {
  faGripVertical,
  faPlus,
  faCircleInfo,
  faTimes,
  faCheckCircle,
  faCircleCheck,
  faTrash,
  faPencil,
  faTriangleExclamation,
  faCopy,
  faCloudArrowUp,
  faInfo,
  faShare,
  faLink,
  faLightbulb,
  faCheck,
  faCaretDown,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faCalendarDays,
  faArrowLeft,
  faEdit,
  faRotateRight,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faAngleLeft,
  faAngleRight,
  faAlignJustify,
  faCircleQuestion,
  faFilePdf,
  faSave,
  faArrowRight,
  faXmark,
  faPaperPlane,
  faFileLines,
  // faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import {
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons'

library.add(faQuestionCircle);
library.add(faEdit)
library.add(faGripVertical);
library.add(faPlus);
library.add(faCircleInfo);
library.add(faTimes);
library.add(faCheckCircle);
library.add(faArrowLeft);
library.add(faSave);
library.add(faArrowRight);
library.add(faTrash);
library.add(faPencil);
library.add(faCaretDown);
library.add(faCheck);
library.add(faChevronRight);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faCalendarDays);
library.add(faTriangleExclamation);
library.add(faCopy);
library.add(faCloudArrowUp);
library.add(faInfo);
library.add(faShare);
library.add(faLink);
library.add(faXmark);
library.add(faLightbulb);
library.add(faRotateRight);
library.add(faMagnifyingGlassMinus);
library.add(faMagnifyingGlassPlus);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faAlignJustify);
library.add(faCircleQuestion);
library.add(faFileLines);
library.add(faFilePdf);
library.add(faCircleCheck);
library.add(faPaperPlane);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('supa-link', SupaLink);
Vue.config.env = process.env;

if (window.errorLoggingConfig) {
  // Sentry.init({
  //   Vue,
  //   dsn: window.errorLoggingConfig,
  //   denyUrls: [
  //     // Chrome extensions
  //     /^chrome:\/\//i,
  //     /chrome-extension:/i,
  //     /extensions\//i,

  //     // Locally saved copies
  //     /file:\/\//i,

  //     // Safari extensions.
  //     /safari-web-extension:/i,
  //     /safari-extension:/i,
  //   ],
  //   integrations: [new Integrations.BrowserTracing()],
  //   ignoreErrors: [
  //     'ResizeObserver loop completed with undelivered notifications',
  //   ],
  // });
}

Vue.use(VueDOMPurifyHTML, domPurifyConfig);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
Vue.use(LottieVuePlayer);
Vue.use(VueFormulate, {
  rules: {
    JSON: ({ value }) => isJSONValid(value),
  },
});
Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(hljs.vuePlugin);
Vue.use(posthog);
Vue.use(AnalyticsPlugin);
Vue.component('multiselect', Multiselect);
Vue.component('woot-switch', WootSwitch);
Vue.component('woot-wizard', WootWizard);
Vue.component('fluent-icon', FluentIcon);

const i18nConfig = new VueI18n({
  locale: 'en',
  messages: i18n,
});

sync(store, router);
// load common helpers into js
commonHelpers();

window.WootConstants = constants;
window.axios = createAxios(axios);
window.bus = new Vue();
initializeChatwootEvents();
initializeAnalyticsEvents();
initalizeRouter();

window.onload = () => {
  window.WOOT = new Vue({
    router,
    store,
    i18n: i18nConfig,
    components: { App },
    template: '<App/>',
  }).$mount('#app');
};

window.addEventListener('load', () => {
  window.playAudioAlert = () => {};
});
